/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getMetabaseIframeUrl } from '../fn/metabase/get-metabase-iframe-url';
import { GetMetabaseIframeUrl$Params } from '../fn/metabase/get-metabase-iframe-url';
import { MetabaseIframeUrl } from '../models/metabase-iframe-url';

@Injectable({ providedIn: 'root' })
export class MetabaseService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getMetabaseIframeUrl()` */
  static readonly GetMetabaseIframeUrlPath = '/api/v1/metabase-iframe-url';

  /**
   * Get signed token for Metabase.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMetabaseIframeUrl()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMetabaseIframeUrl$Response(params: GetMetabaseIframeUrl$Params, context?: HttpContext): Observable<StrictHttpResponse<MetabaseIframeUrl>> {
    return getMetabaseIframeUrl(this.http, this.rootUrl, params, context);
  }

  /**
   * Get signed token for Metabase.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMetabaseIframeUrl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMetabaseIframeUrl(params: GetMetabaseIframeUrl$Params, context?: HttpContext): Observable<MetabaseIframeUrl> {
    return this.getMetabaseIframeUrl$Response(params, context).pipe(
      map((r: StrictHttpResponse<MetabaseIframeUrl>): MetabaseIframeUrl => r.body)
    );
  }

}
